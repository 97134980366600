import "./content-post.scss";

import BlockContent from "@sanity/block-content-to-react";
import { Link } from "gatsby";
import * as React from "react";
import { PropsWithChildren } from "react";

import { left_svg } from "../../../assets/svg/controls_elements";
import { Slug } from "../../../types/shared";

type PropsContentPost = PropsWithChildren<{
  content: Record<string, unknown> | undefined | null;
  date: string | undefined | null;
  nextNewSlug?: Slug;
}>;
const ContentPost: React.FC<PropsContentPost> = (props: PropsContentPost) => {
  const date = props.date ? new Date(props.date) : new Date();
  const formatDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  return (
    <section className={"o-content-post"}>
      <div className={"c-content-post"}>
        <span className={"a-content-post__date"}>{formatDate}</span>
        <div className="m-content-post__body">
          <BlockContent
            blocks={props.content}
            projectId={process.env.GATSBY_SANITY_PROJECT_ID}
            dataset={process.env.GATSBY_SANITY_DATASET}
          />
        </div>
        <div className="m-content-post__navigation">
          <Link
            className={"a-content-post__back"}
            to={"/noticias/"}>
            {" "}
            {left_svg} Volver a las noticias
          </Link>
        </div>
      </div>
    </section>
  );
};

export default ContentPost;
