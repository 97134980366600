import "./hero-post.scss";

import SanityImage from "gatsby-plugin-sanity-image";
import * as React from "react";
import { PropsWithChildren } from "react";

import { Photo } from "../../../types/shared";
import DoNotMountComponent from "../../DoNotMountComponent/DoNotMountComponent";

type PropsHeroPost = PropsWithChildren<{
  title: string | undefined | null;
  categoryName: string | undefined | null;
  categorySlug: string | undefined | null;
  photo: Photo | undefined | null;
}>;

const HeroPost: React.FC<PropsHeroPost> = (props: PropsHeroPost) => {
  if (!props.photo) return <DoNotMountComponent sms={"falta foto en el post"} />;
  return (
    <section className={"o-hero-post"}>
      <div className="c-hero-post">
        <div className={"m-hero-post__info"}>
          <h1>{props.title}</h1>
          <p className={"a-post-card__category"}>{props.categoryName}</p>
        </div>
        <div className={"m-hero-post__2"}>
          <SanityImage
            {...props.photo?.image}
            alt={`${props.photo?.alt}`}
            className={"m-hero-post__photo"}
            width={1274}
            height={660}
          />
        </div>
      </div>
    </section>
  );
};

export default HeroPost;
