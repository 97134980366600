import { Script, ScriptStrategy } from "gatsby";
import * as React from "react";
import { PropsWithChildren } from "react";

type PropsPost = PropsWithChildren<{
  absolutePathPost: string | null | undefined;
  title: string | null | undefined;
  description: string | null | undefined;
  absolutePathImageOG: string | null | undefined;
  authorName: string | null | undefined;
  publishedDate: string | null | undefined;
  modifiedDate: string | null | undefined;
}>;

const PostMarkupSchema: React.FC<PropsPost> = (props: PropsPost) => {
  const markup = `
{
  "@context": "https://schema.org",
  "@type": "Article",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "${props.absolutePathPost}"
  },
  "headline": "${props.title}",
  "description": "${props.description}",
  "image": "${props.absolutePathImageOG}",  
  "author": {
    "@type": "Organization",
    "name": "${props.authorName}"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "Grupo Usieto",
    "logo": {
      "@type": "ImageObject",
      "url": "https://www.grupousieto.com/static/logo.webp"
    }
  },
  "datePublished": "${props.publishedDate}",
  "dateModified": "${props.modifiedDate}"
}
`;
  return (
    <Script
      strategy={ScriptStrategy.postHydrate}
      type="application/ld+json">
      {markup}
    </Script>
  );
};

export default PostMarkupSchema;
