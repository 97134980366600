import "../styles/layouts/post.scss";

import { graphql, PageProps } from "gatsby";
import React from "react";

import { wavePAQs_svg } from "../assets/svg/home_elements";
import HeroPost from "../components/Hero/HeroPost/HeroPost";
import Layout from "../components/Layout/layout";
import ContentPost from "../components/News/ContentPost/ContentPost";
import PostMarkupSchema from "../components/SEO/PostMarkupSchema";
import { SEO } from "../components/SEO/SEO";
import SubscribeNewsletter from "../components/SubscribeNewsletter/SubscribeNewsletter";
import { Problem } from "../components/WeKnowYourProblems/ProblemCard/ProblemCard";
import WeKnowYourProblems from "../components/WeKnowYourProblems/WeKnowYourProblems";
import WeTalk from "../components/WeTalk/WeTalk";
import { Photo } from "../types/shared";

const PostPage = ({ data }: PageProps<Queries.PostPageQuery>) => {
  if (!data.sanityTemplatePost) return;
  return (
    <Layout>
      <div className="l-post">
        <HeroPost
          title={data.sanityTemplatePost.hero?.title}
          categoryName={data.sanityTemplatePost.category?.name}
          categorySlug={data.sanityTemplatePost.category?.slug?.current}
          photo={data.sanityTemplatePost.hero?.photo as unknown as Photo}
        />
        <ContentPost
          content={data.sanityTemplatePost.content}
          date={data.sanityTemplatePost.publishedAt}
        />
        <WeKnowYourProblems
          title={data.sanityPageHome?.weKnowYourProblems?.title}
          problemsList={data.sanityPageHome?.weKnowYourProblems?.PAQsList as unknown as Problem[]}
        />
        <div className="wavePAQs_svg">{wavePAQs_svg}</div>
        <WeTalk />
        <SubscribeNewsletter />
      </div>
    </Layout>
  );
};

export default PostPage;

export const Head = ({ data }: PageProps<Queries.PostPageQuery>) => (
  <>
    <SEO
      title={data.sanityTemplatePost?.seo?.title}
      robots={data.sanityTemplatePost?.seo?.seo_robots}
      description={data.sanityTemplatePost?.seo?.description}
      imageAbsolutePath={data.sanityTemplatePost?.hero?.photo?.image?.asset?.url}
    />
    <PostMarkupSchema
      absolutePathPost={`${data.sanityDocumentData?.urlSite}${data.sanityTemplatePost?.slug.current}`}
      title={data.sanityTemplatePost?.hero?.title}
      description={data.sanityTemplatePost?.seo?.description}
      absolutePathImageOG={data.sanityTemplatePost?.hero?.photo?.image?.asset?.url}
      authorName={"Grupo Usieto"}
      publishedDate={data.sanityTemplatePost?.publishedAt}
      modifiedDate={data.sanityTemplatePost?.publishedAt}
    />
  </>
);

export const query = graphql`
  query PostPage($id: String) {
    sanityDocumentData {
      urlSite
    }
    sanityTemplatePost(id: { eq: $id }) {
      slug {
        current
      }
      _updatedAt: publishedAt
      publishedAt
      seo {
        title
        seo_robots
        description
      }

      category {
        name
        slug {
          current
        }
      }
      content: _rawContent
      hero {
        title
        photo {
          image {
            asset {
              gatsbyImageData
              _id
              url
            }
          }
        }
      }
    }
    allSanityTemplatePost {
      edges {
        next {
          slug {
            current
          }
        }
      }
    }
    sanityPageHome {
      weKnowYourProblems {
        title
        PAQsList: _rawPaQsList
      }
    }
  }
`;
